<template>
  <div class="global-settings">
    <h1 class="title--1 title--unspaced">
      Paramètres de votre site
    </h1>

    <div class="global-settings__block">
      <div class="settings-block">
        <WebsiteURL />
      </div>
    </div>

    <div class="global-settings__block">
      <div class="settings-block">
        <AddressAndName />
      </div>
    </div>

    <div class="global-settings__block">
      <Contact />
    </div>

    <div class="global-settings__block">
      <DescriptionAndFees />
    </div>

    <div class="global-settings__block">
      <Logo />
    </div>

    <div class="global-settings__block">
      <SocialNetworks />
    </div>

    <div class="global-settings__block">
      <Coworkers />
    </div>
  </div>
</template>

<script>
import {
  AddressAndName,
  Contact,
  Logo,
  SocialNetworks,
  Coworkers,
  DescriptionAndFees,
} from '../SettingsBlocks';
import WebsiteURL from '@/views/Dashboard/SiteSettings/SettingsBlocks/WebsiteURL';

export default {
  components: {
    WebsiteURL,
    AddressAndName,
    Contact,
    Logo,
    SocialNetworks,
    Coworkers,
    DescriptionAndFees,
  },
};
</script>
