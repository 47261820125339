import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
dayjs.extend(customParseFormat);

export var datesMixin = {
  methods: {
    formattedDateFR(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },

    formattedDateUS(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },

    formattedHour(date) {
      return dayjs(date).format('HH:mm');
    },

    nextMonth(date) {
      return dayjs(date).add(1, 'month');
    },

    toISOStringKeepingOffset(date) {
      return dayjs(date).toISOString();
    },

    hourToInt: hour => {
      const time = dayjs(hour, 'HH:mm:ss');

      return time.hour() + time.minute() / 60;
    },

    intToHour: int => {
      if (!int) {
        return null;
      }

      let time = dayjs('00:00:00', 'HH:mm:ss');

      time = time
        .hour(Math.floor(int))
        .minute((int % 1) * 60)
        .second(0);

      return time.format('HH:mm:ss');
    },

    applyHoursMinutesToDate(date, hours, minutes) {
      const result = new Date(date);

      result.setHours(hours);
      result.setMinutes(minutes);

      return result;
    },
  },
};
