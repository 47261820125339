<template>
  <div class="step">
    <p class="text text--turquoise text--spaced">
      ENCORE QUELQUES INSTANTS, NOUS PRÉPARONS VOTRE SITE.
    </p>
    <h1 class="title--1">
      Chargement de vos fonctionnalités en cours...
    </h1>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { api } from '@/lib/api';

export default {
  computed: {
    ...mapGetters({
      getOfficeState: 'office/getState',

      getSiteState: 'site/getSite',

      getSiteVersionState: 'siteVersion/getState',
    }),
  },

  async created() {
    const { data: office } = await api.post('/offices', this.getOfficeState);
    this.updateSiteOffice(office['@id']);

    const { data: site } = await api.post('/sites', this.getSiteState);
    this.updateSiteVersionSite(site['@id']);

    const { data: siteVersion } = await api.post(
      '/site_versions',
      this.getSiteVersionState
    );

    this.updateSiteVersionSite(siteVersion.site);
    this.updateSite(siteVersion.site);

    this.$emit('next-step');
  },

  methods: {
    ...mapActions({
      updateSiteOffice: 'site/updateOffice',

      updateSiteVersionSite: 'siteVersion/updateSite',
      updateSite: 'site/updateSite',
    }),
  },
};
</script>
