<template>
  <div class="time-selector">
    <div
      :class="[
        'time-selector__slider',
        { 'time-selector__slider--disabled': disabled },
        { 'cursor--not-allowed': disabled },
        { 'cursor--pointer': !disabled },
      ]"
    >
      <v-slider
        :value="value"
        :min="min"
        :max="max"
        :interval="0.5"
        :enable-cross="false"
        :tooltip-formatter="formatter"
        :disabled="disabled"
        marks
        hide-label
        @change="$emit('change', $event)"
      />
    </div>

    <div class="time-selector__slider-details">
      <span>{{ min }}h</span>
      <span>{{ max }}h</span>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  components: {
    'v-slider': VueSlider,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Array,
      default: () => [],
    },

    min: {
      type: Number,
      default: 7,
    },

    max: {
      type: Number,
      default: 11,
    },
  },

  methods: {
    formatter(value) {
      if (value % 1 === 0.5) {
        return Math.floor(value) + ':30';
      }

      return value + ':00';
    },
  },
};
</script>
