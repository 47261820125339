<template>
  <div class="step step--spaced">
    <h1 class="title--1 title--little-spaced">
      Pouvez-vous décrire votre <br />
      cabinet ?
    </h1>
    <p class="text text--hint">
      Cette description sera utilisée sur votre site, soyez concis et clair.
      <br />
      La description et les honoraires doivent avoir un minimum de 50 caractères.
    </p>

    <div class="description">
      <textarea
        v-model="siteVersionDescription"
        class="input input--textarea description__input"
        :maxlength="descriptionMaxLength"
        :placeholder="
          'Vous pouvez indiquer ici une courte description, n\'hésitez pas à mentionner :\n- Structure d\'exercice\n- Réseau dont le cabinet est membre'
        "
      />

      <p class="text text--validation">
        {{ remainingCharacters }} CARACTÈRES RESTANTS
      </p>
    </div>

    <div class="fees">
      <p class="text text--hint">Précisez vos honoraires (facultatif)</p>
      <textarea
        v-model="siteVersionFees"
        class="input input--textarea fees__input"
        :maxlength="feesMaxLength"
        :placeholder="
          'Le CNB préconise de mentionner les informations suivantes : \n- Le montant des honoraires. \n- Le mode de détermination des honoraires \n- Les modes de facturation des honoraires. \n- possibilité de saisir le bâtonnier pour contester les honoraires (articles 174 et suivants du décret du 27 novembre 1991) \n- La possibilité de recourir à un médiateur de la consommation'
        "
      />
      <p class="text text--validation">
        {{ remainingFeesCharacters }} CARACTÈRES RESTANTS
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      descriptionMaxLength: 800,
      feesMaxLength: 800,
      siteVersionDescription: '',
      siteVersionFees: '',
    };
  },

  created() {
    this.siteVersionDescription = this.getSiteVersionDescription;
    this.siteVersionFees = this.getSiteVersionFees;
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    siteVersionDescription(description) {
      if (!this.$v.siteVersionDescription.$invalid) {
        this.updateSiteVersionDescription(description);
      }
    },

    siteVersionFees(description) {
      if (!this.$v.siteVersionFees.$invalid) {
        this.updateSiteVersionFees(description);
      }
    },
  },

  computed: {
    ...mapGetters({
      getSiteVersionDescription: 'siteVersion/getDescription',
      getSiteVersionFees: 'siteVersion/getFees',
    }),

    remainingCharacters() {
      return this.descriptionMaxLength - this.siteVersionDescription.length;
    },

    remainingFeesCharacters() {
      return this.feesMaxLength - this.siteVersionFees.length;
    },
  },

  methods: {
    ...mapActions({
      updateSiteVersionDescription: 'siteVersion/updateDescription',
      updateSiteVersionFees: 'siteVersion/updateFees',
    }),
  },

  validations() {
    return {
      siteVersionDescription: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(this.descriptionMaxLength),
      },
      siteVersionFees: {
        minLength: minLength(50),
        maxLength: maxLength(this.feesMaxLength),
      },
    };
  },
};
</script>
