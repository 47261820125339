<template>
  <div class="dashboard-home">
    <div class="dashboard-home__header">
      <h1>Bienvenue sur votre espace</h1>
    </div>

    <div class="dashboard-home__cards">
      <div
        v-for="(item, index) in itemsToDisplay"
        :key="index"
        :class="`dashboard-card dashboard-card--background${index}`"
      >
        <div class="dashboard-card__content">
          <div class="dashboard-card__text-wrapper">
            <h3>{{ item.title }}</h3>
            <p class="text text--on-background">{{ item.description }}</p>
          </div>
          <router-link
            v-if="item.buttonRoute"
            :to="{ name: item.buttonRoute }"
            tag="button"
            class="button button--small"
          >
            {{ item.buttonLabel }}
          </router-link>
          <a
            v-else
            :href="eLearningUrl"
            class="button button--small"
            target="_blank"
          >
            {{ item.buttonLabel }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="getSiteVersionStatus === 'PROD'">
      <div class="dashboard-home__header">
        <h1>Ma messagerie</h1>
        <p class="text text--hint">
          Retrouvez l’ensemble des messages qui vous ont été adressés depuis le
          formulaire de contact de votre site.
        </p>
      </div>

      <div v-if="messages.length" class="dashboard-home__messages">
        <Message
          v-for="(message, index) in messages"
          :key="index"
          :message="message"
        ></Message>
      </div>
      <p v-else class="text text--large text--center">Vous n'avez aucun message !</p>
    </div>
  </div>
</template>

<script>
import Message from '@/views/Dashboard/Messages/Message';
import { mapGetters } from 'vuex';

export default {
  components: { Message },

  props: {
    messages: Array,
  },

  data() {
    return {
      items: [
        {
          title: 'Gérer mon site',
          description:
            'Accéder directement à l’édition de vos contenus et à la modification graphique de votre site web.',
          buttonLabel: 'GÉRER MON SITE',
          buttonRoute: 'SiteSettings',
        },
        {
          title: 'Les performances de mon site',
          description:
            'Accéder à toutes les données de navigation de mon site et de mes visiteurs : nombre de visiteurs, durée de session, pages visitées, devices utilisés…',
          buttonLabel: 'VOIR MES PERFORMANCES',
          buttonRoute: 'Stats',
        },
        {
          title: 'Mon espace e-learning',
          description: 'Accéder directement à notre catalogue de formation',
          buttonLabel: 'MON ESPACE E-LEARNING',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      eLearningUrl: 'user/getELearningUrl',
      getSiteVersionStatus: 'siteVersion/getStatus',
    }),
    itemsToDisplay() {
      return this.items.filter(
        item => item.buttonRoute !== 'Stats' || this.getSiteVersionStatus === 'PROD'
      );
    },
  },
};
</script>
