<template>
  <div class="appointment-modal">
    <Modal width="medium">
      <template #header>
        <h2 class="title title--2 title--bold text--center">
          Rendez-vous demandé par {{ appointment['participantFirstName'] }}
          {{ appointment['participantLastName'] }}
        </h2>
      </template>

      <template #body>
        <p
          :class="[
            'text',
            'text--2',
            { 'text--strikethrough': appointmentDeferralRequestExists },
          ]"
        >
          Date :
          <span class="text text--hint-light">
            le {{ formattedDateFR(appointment['startDatetime']) }}
          </span>
        </p>

        <p
          :class="[
            'text',
            'text--2',
            { 'text--strikethrough': appointmentDeferralRequestExists },
          ]"
        >
          Horaires :
          <span class="text text--hint-light">
            de {{ formattedHour(appointment['startDatetime']) }} à
            {{ formattedHour(appointment['endDatetime']) }}
          </span>
        </p>

        <p class="text text--2">
          Téléphone :
          <span class="text text--hint-light">
            {{ appointment.participantPhoneNumber }}
          </span>
        </p>

        <div v-if="appointmentDeferralRequestExists">
          <p class="text text--2">Nouvelle date proposée :</p>
          <p class="text text--2 text--hint-light">
            Le {{ formattedDateFR(existingDeferralRequest.newStart) }}, de
            {{ formattedHour(existingDeferralRequest.newStart) }} à
            {{ formattedHour(existingDeferralRequest.newEnd) }}.
            <span
              v-if="!existingAppointmentDeferralRequest.isAccepted"
              class="text text--not-available"
            >
              (en attente d'acceptation)
            </span>
          </p>
        </div>

        <p class="text text--2 text--without-margin-bottom">Message :</p>
        <p class="text text--2 text--hint-dark text--with-margin-bottom">
          {{ appointment['linkedMessage'] }}
        </p>

        <div v-if="showVideoConferenceSection">
          <p class="text text--2 text--without-margin-bottom">
            Lien vers la visio-conférence :
          </p>

          <WebsiteLink :url="appointment['conferenceURL']" :margin-bottom="true" />
        </div>

        <div v-if="!isReadOnly" class="appointment-modal__actions">
          <button
            v-if="mustActionBeShown(acceptAction)"
            class="appointment-modal__action-button"
            @click="accept"
          >
            Accepter
          </button>

          <button
            v-if="mustActionBeShown(deferAction)"
            class="appointment-modal__action-button"
            @click="defer"
          >
            Proposer un autre créneau
          </button>

          <button
            v-if="mustActionBeShown(declineAction)"
            class="appointment-modal__action-button"
            @click="decline"
          >
            Refuser le rendez-vous
          </button>

          <button
            v-if="mustActionBeShown(cancelAction)"
            class="appointment-modal__action-button"
            @click="cancel"
          >
            Annuler le rendez-vous
          </button>
        </div>
      </template>

      <template #footer>
        <slot name="footer" />
      </template>
    </Modal>

    <Modal v-if="mustConfirmationModalBeDisplayed">
      <template #header>
        <p class="title title--3 text--center">Confirmation</p>
      </template>

      <template #body>
        <p class="text text--1 text--center text--with-margin-bottom">
          Cette action est irréversible. Voulez vous continuer ?
        </p>
      </template>

      <template #footer>
        <div class="buttons-group buttons-group--2">
          <button class="button button--x-small" @click="hideConfirmationModal">
            <span>Annuler</span>
          </button>
          <button class="button button--x-small" @click="confirm">
            <span>Continuer</span>
          </button>
        </div>
      </template>
    </Modal>

    <Modal v-if="mustDeferralRequestModalBeDisplayed" width="medium">
      <template #header>
        <p class="title title--2 text--center">Proposer un autre créneau</p>
      </template>

      <template #body>
        <p class="text text--1 text--center text--with-margin-bottom">
          Sélectionnez une date :
        </p>

        <div class="deferral-modal__date-picker-wrapper">
          <date-picker
            v-model="selectedDate"
            color="green"
            is-required
            is-expanded
          />
        </div>

        <p class="text text--1 text--center text--with-margin-bottom">
          Saisissez les heures de début et de fin du nouveau rendez-vous proposé :
        </p>

        <div class="time-selectors-group">
          <div class="time-selectors-group__item time-selector">
            <input
              v-model="newDeferralRequest.start.hours"
              type="number"
              class="time-selector__input"
              placeholder="10"
              min="0"
              max="23"
            />

            <span class="time-selector__hours-minutes-separator"> : </span>

            <input
              v-model="newDeferralRequest.start.minutes"
              type="number"
              class="time-selector__input"
              placeholder="00"
              min="0"
              max="59"
            />
          </div>

          <span class="time-selector__hours-separator"> à </span>

          <div class="time-selector">
            <input
              v-model="newDeferralRequest.end.hours"
              type="number"
              class="time-selector__input"
              placeholder="11"
              min="0"
              max="23"
            />

            <span class="time-selector__hours-minutes-separator"> : </span>

            <input
              v-model="newDeferralRequest.end.minutes"
              type="number"
              class="time-selector__input"
              placeholder="00"
              min="0"
              max="59"
            />
          </div>
        </div>

        <div class="deferral-modal__errors">
          <p
            v-if="showDeferralRequestDateError"
            class="text text--2 text--not-available"
          >
            L'heure de début du rendez-vous doit être inférieure à celle de fin.
          </p>
        </div>
      </template>

      <template #footer>
        <div class="deferral-modal__buttons-group buttons-group buttons-group--2">
          <button class="button button--x-small" @click="hideDeferralRequestModal">
            <span>Annuler</span>
          </button>
          <button
            :class="[
              'button',
              'button--x-small',
              { 'button--invalid': isDeferralRequestInvalid },
            ]"
            @click="confirm"
            :disabled="isDeferralRequestInvalid"
          >
            <span>Confirmer et envoyer</span>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import { datesMixin } from '@/mixins/datesMixin';
import { api } from '@/lib/api';
import WebsiteLink from '@/components/WebsiteLink';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {
  required,
  numeric,
  minLength,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';

const DEFAULT_DEFERRAL_REQUEST = {
  start: {
    hours: '',
    minutes: '',
  },
  end: {
    hours: '',
    minutes: '',
  },
};

const HOURS_VALIDATORS = {
  required,
  numeric,
  minLength: minLength(2),
  minValue: minValue(0),
  maxValue: maxValue(23),
};

const MINUTES_VALIDATORS = {
  required,
  numeric,
  minLength: minLength(2),
  minValue: minValue(0),
  maxValue: maxValue(59),
};

const ACTIONS = {
  accept: { name: 'acceptAction', status: 'ACCEPTED' },
  defer: { name: 'deferAction' },
  cancel: { name: 'cancelAction', status: 'CANCELED' },
  decline: { name: 'declineAction', status: 'REFUSED' },
};

export default {
  components: { WebsiteLink, Modal, DatePicker },

  mixins: [datesMixin],

  props: {
    appointment: {
      type: Object,
      required: true,
    },
    acceptAction: Boolean,
    deferAction: Boolean,
    declineAction: Boolean,
    cancelAction: Boolean,
    showConferenceUrl: Boolean,
  },

  data() {
    return {
      mustConfirmationModalBeDisplayed: false,
      mustDeferralRequestModalBeDisplayed: false,
      currentAction: null,
      selectedDate: new Date(this.appointment?.['date']),
      existingDeferralRequest: {},
      newDeferralRequest: {
        ...DEFAULT_DEFERRAL_REQUEST,
      },
    };
  },

  async mounted() {
    if (this.appointmentDeferralRequestExists) {
      await this.refreshAppointmentDeferralRequest();
    }
  },

  computed: {
    isReadOnly() {
      return !(
        this.acceptAction ||
        this.deferAction ||
        this.declineAction ||
        this.cancelAction
      );
    },

    isDeferralRequestInvalid() {
      return (
        this.areDeferralRequestFieldsInvalid ||
        this.isStartDateGreaterThanOrEqualToEndDate
      );
    },

    areDeferralRequestFieldsInvalid() {
      return this.$v.$invalid;
    },

    appointmentDeferralRequestExists() {
      return !(
        this.appointment['appointmentDeferralRequest'] === undefined ||
        this.appointment['appointmentDeferralRequest'] === null
      );
    },

    existingAppointmentDeferralRequest() {
      if (this.appointmentDeferralRequestExists) {
        return {};
      }

      return {
        start: new Date(this.existingDeferralRequest.newStart),
        end: new Date(this.existingDeferralRequest.newEnd),
        isAccepted: new Date(this.existingDeferralRequest.isAccepted),
      };
    },

    isStartDateGreaterThanOrEqualToEndDate() {
      if (this.newDeferralRequest.start.hours < this.newDeferralRequest.end.hours) {
        return false;
      }

      if (this.newDeferralRequest.start.hours > this.newDeferralRequest.end.hours) {
        return true;
      }

      return (
        this.newDeferralRequest.start.minutes >= this.newDeferralRequest.end.minutes
      );
    },

    showDeferralRequestDateError() {
      return (
        !this.areDeferralRequestFieldsInvalid &&
        this.isStartDateGreaterThanOrEqualToEndDate
      );
    },

    showVideoConferenceSection() {
      return (
        this.showConferenceUrl && this.appointment.appointmentType === 'VIDEO_CALL'
      );
    },
  },

  methods: {
    async accept() {
      this.currentAction = ACTIONS.accept.name;
      await this.confirm();
    },

    defer() {
      this.currentAction = ACTIONS.defer.name;
      this.showDeferralRequestModal();
    },

    decline() {
      this.currentAction = ACTIONS.decline.name;
      this.showConfirmationModal();
    },

    cancel() {
      this.currentAction = ACTIONS.cancel.name;
      this.showConfirmationModal();
    },

    showConfirmationModal() {
      if ([ACTIONS.decline.name, ACTIONS.cancel.name].includes(this.currentAction)) {
        this.mustConfirmationModalBeDisplayed = true;
      }
    },

    hideConfirmationModal() {
      this.currentAction = null;
      this.mustConfirmationModalBeDisplayed = false;
    },

    showDeferralRequestModal() {
      if (this.currentAction === ACTIONS.defer.name) {
        this.selectedDate = this.appointment['date'];
        this.mustDeferralRequestModalBeDisplayed = true;
      }
    },

    mustActionBeShown(action) {
      return action && !this.appointmentDeferralRequestExists;
    },

    hideDeferralRequestModal() {
      this.selectedDate = undefined;
      this.newDeferralRequest = {
        ...DEFAULT_DEFERRAL_REQUEST,
      };
      this.mustDeferralRequestModalBeDisplayed = false;
    },

    async confirm() {
      await this.sendRequest();

      this.currentAction = null;
      this.refreshAppointmentsList();
    },

    async sendRequest() {
      if (this.currentAction === ACTIONS.defer.name) {
        await this.sendDeferralRequest();
        return;
      }

      await api.put(this.appointment['@id'], {
        status: ACTIONS[this.currentAction.replace('Action', '')].status,
      });
    },

    async sendDeferralRequest() {
      try {
        await api.post('/appointment_deferral_requests', {
          appointment: this.appointment['@id'],

          newStart: this.toISOStringKeepingOffset(
            this.applyHoursMinutesToDate(
              this.selectedDate,
              this.newDeferralRequest.start.hours,
              this.newDeferralRequest.start.minutes
            )
          ),

          newEnd: this.toISOStringKeepingOffset(
            this.applyHoursMinutesToDate(
              this.selectedDate,
              this.newDeferralRequest.end.hours,
              this.newDeferralRequest.end.minutes
            )
          ),
        });
      } catch (error) {
        return;
      }

      this.hideDeferralRequestModal();
    },

    async refreshAppointmentDeferralRequest() {
      const { data } = await api.get(this.appointment['appointmentDeferralRequest']);
      this.existingDeferralRequest = data;
    },

    refreshAppointmentsList() {
      this.$emit('refresh-appointments-list');
    },
  },

  validations() {
    return {
      newDeferralRequest: {
        start: {
          hours: {
            ...HOURS_VALIDATORS,
          },
          minutes: {
            ...MINUTES_VALIDATORS,
          },
        },
        end: {
          hours: {
            ...HOURS_VALIDATORS,
          },
          minutes: {
            ...MINUTES_VALIDATORS,
          },
        },
      },
    };
  },
};
</script>
