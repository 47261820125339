<template>
  <div class="credentials-component">
    <div v-if="isIdDefined" class="credentials-component__row">
      <div class="credentials-component__data">
        <p class="credentials-component__label">Identifiant :</p>
        <p class="credentials-component__value">{{ login }}</p>
      </div>
    </div>

    <div class="credentials-component__row">
      <div class="credentials-component__data">
        <p class="credentials-component__label">Mot de passe :</p>
        <p
          :class="[
            'credentials-component__value',
            'credentials-component__value--can-be-hidden',
            { 'credentials-component__value--hidden': isPasswordHidden },
          ]"
        >
          {{ password }}
        </p>
      </div>

      <span
        class="credentials-component__show-value-button"
        @click="toggleIsPasswordHidden"
        aria-label="Afficher le mot de passe"
      >
        Afficher
      </span>
    </div>

    <div
      class="credentials-component__edit-credentials-button"
      @click="editCredentials"
    >
      Modifier mes identifiants
    </div>
  </div>
</template>

<script>
export default {
  props: {
    login: {
      type: String,
      default: null,
    },

    password: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isPasswordHidden: true,
    };
  },

  computed: {
    isIdDefined() {
      return this.login !== null;
    },
  },

  methods: {
    toggleIsPasswordHidden() {
      this.isPasswordHidden = !this.isPasswordHidden;
    },

    editCredentials() {
      this.$emit('edit-credentatials');
    },
  },
};
</script>
