<template>
  <div class="welcome">
    <div class="welcome__header">
      <div>
        <img src="@/assets/svg/logo-solency.svg" />
      </div>
      <div>
        <h1 class="title">
          <span class="title--bold">Cher(e) Maître, bienvenue sur Solency !</span
          ><br />
          Par quoi souhaitez-vous commencer ?
        </h1>
      </div>
    </div>
    <div class="welcome__cards">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="`welcome-card welcome-card--style${index}`"
      >
        <div class="welcome-card__content">
          <h3 :class="`welcome-card--style${index}--color`">
            {{ item.title }}
          </h3>
          <router-link
            v-if="item.buttonRoute"
            :to="{ name: item.buttonRoute }"
            tag="button"
            :class="`button button--small welcome-card--style${index}--button-color`"
          >
            <span>{{ item.buttonLabel }}</span>
          </router-link>
          <a
            v-else
            :href="eLearningUrl"
            :class="`button button--small welcome-card--style${index}--button-color`"
            target="_blank"
          >
            <span>{{ item.buttonLabel }}</span>
          </a>
        </div>
      </div>
    </div>
    <Footer :marginTop="true" />
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';
import Footer from '@/components/Footer';

export default {
  components: { Footer },
  async created() {
    const userId = this.getId;

    const { data: user } = await api.get(`/users/${userId}`);

    this.updateUser(user);
  },

  data() {
    return {
      items: [
        {
          title: 'Créer gratuitement votre site web en quelques minutes',
          buttonLabel: 'JE CRÉE MON SITE',
          buttonRoute: 'StepZero',
        },
        {
          title: 'Accéder au catalogue de formations e-learning',
          buttonLabel: `JE ME FORME`,
        },
      ],
      apiURL: process.env.VUE_APP_API_URL,
    };
  },

  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      eLearningUrl: 'user/getELearningUrl',
    }),
  },

  methods: {
    ...mapActions({
      updateUser: 'user/updateUser',
    }),
  },
};
</script>
