import { apiSetJwt } from '@/lib/api';

const state = {
  userLogged: false,
  eLearningUrl: '',
};

const getters = {
  isLogged: state => state.userLogged,
  getELearningUrl: state => state.eLearningUrl,
};

const mutations = {
  setUserLogged(state, token) {
    state.userLogged = true;
    apiSetJwt(token);
  },

  logout(state) {
    state.userLogged = false;
    apiSetJwt('');
  },

  setELearningUrl(state, url) {
    state.eLearningUrl = url;
  },
};

const actions = {
  login({ commit, rootGetters }, token) {
    if (rootGetters['auth/isTokenValid']) {
      commit('setUserLogged', token);
    }
  },

  logout({ commit }) {
    commit('logout');
  },

  updateUser({ commit }, user) {
    commit('setELearningUrl', user.eLearningUrl);
  },
};

export default { namespaced: true, actions, state, mutations, getters };
