<template>
  <div class="site-settings">
    <div class="site-settings__header">
      <div class="site-settings__header__tabs">
        <a
          :class="[
            'site-settings__header__tabs__tab',
            {
              'site-settings__header__tabs__tab--active':
                currentTab === 'GlobalSettings',
            },
          ]"
          @click="goToStep('GlobalSettings')"
        >
          Étape 1
        </a>
        <a
          :class="[
            'site-settings__header__tabs__tab',
            {
              'site-settings__header__tabs__tab--active':
                currentTab === 'DesignSettings',
            },
          ]"
          @click="goToStep('DesignSettings')"
        >
          Étape 2
        </a>
        <a
          :class="[
            'site-settings__header__tabs__tab',
            { 'site-settings__header__tabs__tab--active': currentTab === 'Legals' },
          ]"
          @click="goToStep('Legals')"
        >
          Étape 3
        </a>
        <a
          :class="[
            'site-settings__header__tabs__tab',
            {
              'site-settings__header__tabs__tab--active': currentTab === 'LiveEdit',
            },
          ]"
          @click="goToStep('LiveEdit')"
        >
          <img
            src="@/assets/svg/live-edit.svg"
            class="site-settings__header__tabs__tab__icon"
            alt=""
          />
          Prévisualisation
        </a>
      </div>
      <div class="site-settings__header__cta">
        <div v-if="displayLiveEditCtas" class="site-settings__header__cta__icons">
          <img
            src="@/assets/svg/preview-desktop.svg"
            alt=""
            :class="[
              'site-settings__header__cta__icon',
              {
                'site-settings__header__cta__icon--active':
                  previewSize === previewSizeDesktop,
              },
            ]"
            @click="previewSize = previewSizeDesktop"
          />
          <img
            src="@/assets/svg/preview-tablet.svg"
            alt=""
            :class="[
              'site-settings__header__cta__icon',
              {
                'site-settings__header__cta__icon--active':
                  previewSize === previewSizeTablet,
              },
            ]"
            @click="previewSize = previewSizeTablet"
          />
          <img
            src="@/assets/svg/preview-mobile.svg"
            alt=""
            :class="[
              'site-settings__header__cta__icon',
              {
                'site-settings__header__cta__icon--active':
                  previewSize === previewSizeMobile,
              },
            ]"
            @click="previewSize = previewSizeMobile"
          />
        </div>
        <button
          v-if="currentTab === 'LiveEdit'"
          class="site-settings__header__cta__button button button--negative button--x-small"
          @click="save"
        >
          ENREGISTRER
        </button>
        <button
          v-if="currentTab === 'LiveEdit'"
          class="site-settings__header__cta__button button button--x-small"
          @click="publish"
        >
          PUBLIER
        </button>
      </div>
    </div>
    <div class="site-settings__body">
      <GlobalSettings v-if="currentTab === 'GlobalSettings'" />
      <DesignSettings v-if="currentTab === 'DesignSettings'" />
      <Legals v-if="currentTab === 'Legals'" />
      <LiveEdit v-if="currentTab === 'LiveEdit'" :preview-size="previewSize" />
      <div class="site-settings__step-buttons-wrapper">
        <button
          v-if="['GlobalSettings', 'DesignSettings', 'Legals'].includes(currentTab)"
          class="site-settings__next-step-button button button--icon button--x-small"
          @click="nextStep"
        >
          <span>Étape suivante</span>
        </button>
        <a
          class="site-settings__previous-step-button link"
          v-show="['DesignSettings', 'Legals'].includes(currentTab)"
          @click="previousStep"
        >
          <span>Retour à l'étape précédente</span>
        </a>
      </div>
    </div>
    <div class="site-settings__mobile-warning">
      <h1>
        Cette fonctionnalité n'est pour le moment pas disponible sur mobile
      </h1>
    </div>

    <transition name="modal">
      <Modal v-if="showSaveModal" icon>
        <template v-slot:header>
          <h3>Votre site est enregistré !</h3>
        </template>
        <template v-slot:body>
          <p class="text text--2 text--hint text--hint-light">
            Mais attention il n’est pas encore en ligne. Pour le mettre en ligne,
            veuillez cliquer sur Publier.
          </p>
        </template>
      </Modal>
    </transition>

    <transition name="modal">
      <Modal v-if="showPublishModal" icon>
        <template v-slot:header>
          <h3 v-if="isFirstPublish" class="text--center">
            Bravo ! Votre site est en cours de mise en ligne. Il sera accessible
            d’ici 24H à 48H.
          </h3>
          <h3 v-else class="text--center">Votre site a été mis à jour</h3>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import { Modal } from '@/components';

import { GlobalSettings, DesignSettings, Legals, LiveEdit } from './Tabs';

export default {
  components: {
    Modal,
    GlobalSettings,
    DesignSettings,
    Legals,
    LiveEdit,
  },

  data() {
    return {
      showSaveModal: false,
      showPublishModal: false,
      isFirstPublish: false,
      previewSize: '100%',
      previewSizeMobile: '550px',
      previewSizeTablet: '992px',
      previewSizeDesktop: '100%',
      statusDraft: 'DRAFT',
      statusProd: 'PROD',

      currentTab: 'GlobalSettings',
    };
  },

  computed: {
    ...mapGetters({
      getSiteVersionState: 'siteVersion/getState',
      getSiteVersionSite: 'siteVersion/getSite',
      getSiteVersionStatus: 'siteVersion/getStatus',
      getOffice: 'office/getState',
      getSite: 'site/getState',
    }),

    displayLiveEditCtas() {
      return this.$route.name === 'LiveEdit';
    },
  },

  methods: {
    ...mapActions({
      updateSiteVersionStatus: 'siteVersion/updateStatus',
      updateSiteHostingSettings: 'site/updateHostingSettings',
      updateSiteOffice: 'site/updateOffice',
      updateSiteVersionSite: 'siteVersion/updateSite',
      updateOffice: 'office/updateOffice',
      updateSite: 'site/updateSite',
    }),

    nextStep() {
      switch (this.currentTab) {
        case 'GlobalSettings':
          this.currentTab = 'DesignSettings';
          break;
        case 'DesignSettings':
          this.currentTab = 'Legals';
          break;
        case 'Legals':
          this.currentTab = 'LiveEdit';
          break;
      }
      this.postSiteVersion();
      window.scrollTo(0, 0);
    },

    previousStep() {
      switch (this.currentTab) {
        case 'DesignSettings':
          this.currentTab = 'GlobalSettings';
          break;
        case 'Legals':
          this.currentTab = 'DesignSettings';
          break;
        case 'LiveEdit':
          this.currentTab = 'Legals';
          break;
      }
      this.postSiteVersion();
      window.scrollTo(0, 0);
    },

    goToStep(step) {
      if (!this.isCurrentStepIndexGreaterThan(step)) {
        return;
      }
      this.currentTab = step;
      this.postSiteVersion();
    },

    getStepIndex(step) {
      switch (step) {
        case 'GlobalSettings':
          return 0;
        case 'DesignSettings':
          return 1;
        case 'Legals':
          return 2;
        case 'LiveEdit':
          return 3;
      }
    },

    isCurrentStepIndexGreaterThan(step) {
      return this.getStepIndex(this.currentTab) > this.getStepIndex(step);
    },

    save() {
      this.updateSiteVersionStatus(this.statusDraft);
      this.postSiteVersion();
      this.showSaveModal = true;
      setTimeout(() => {
        this.showSaveModal = false;
      }, 2000);
    },

    publish() {
      this.isFirstPublish = this.getSiteVersionStatus === this.statusDraft;
      this.updateSiteVersionStatus(this.statusProd);
      this.postSiteVersion();
      this.showPublishModal = true;
      setTimeout(() => {
        this.showPublishModal = false;
      }, 2000);
    },

    async postSiteVersion() {
      const { data: office } = await api.put(this.getOffice.uri, this.getOffice);
      this.updateOffice(office);

      const { data } = await api.post('/site_versions', {
        ...this.getSiteVersionState,
        site: this.getSite.uri,
      });

      this.updateSiteHostingSettings(data.site.hostingSettings);
    },
  },
};
</script>
