<template>
  <div class="auth">
    <div class="auth__image">
      <figure class="auth__image__logo">
        <img src="@/assets/svg/logo-solency-white.svg" alt="" />
      </figure>
      <h1 class="title title--white auth__image__title">
        Solency <br />
        accompagne les <br />
        avocats dans leur <br />
        <span class="title title__highlight">digitalisation</span>.
      </h1>
      <p class="text text--white">
        Copyright {{ currentYear }}© Solency -
        <a
          class="text--white link--no-decoration"
          :href="apiURL + '/files/Conditions_d_utilisation-Solency.pdf'"
          target="_blank"
          >Conditions d’utilisation</a
        >
        -
        <a
          class="text--white link--no-decoration"
          :href="apiURL + '/files/Notice_RGPD-Solency.pdf'"
          target="_blank"
          >Politique RGPD</a
        >
      </p>
    </div>
    <div class="auth__form">
      <div class="auth__form__nav">
        <div class="auth__form__nav__links">
          <div class="auth__form__nav__link__border">
            <router-link
              :class="[
                'auth__form__nav__link',
                { 'auth__form__nav__link--active': noAccountLinkActive },
              ]"
              :to="{ name: 'Register' }"
            >
              Je n'ai pas de compte
            </router-link>
          </div>
          <router-link
            :class="[
              'auth__form__nav__link',
              { 'auth__form__nav__link--active': accountLinkActive },
            ]"
            :to="{ name: 'Connection' }"
          >
            J'ai un compte
          </router-link>
        </div>
      </div>
      <div class="auth__form__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      apiURL: process.env.VUE_APP_API_URL,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    accountLinkActive() {
      const routes = [
        'Connection',
        'ResetPassword',
        'ForgotPassword',
        'EmailCheckIn',
      ];

      return routes.includes(this.$route.name);
    },
    noAccountLinkActive() {
      const routes = ['Register', 'VerifyEmail'];

      return routes.includes(this.$route.name);
    },
  },
};
</script>
