const initialState = () => {
  return {
    uri: '',
    id: '',
    hostingSettings: [],
    tagManagerId: '',
    oauthClientId: '',
    office: '',
    token: '',
    draftSiteVersion: '',
    productionSiteVersion: '',
    messages: [],
    existingDomain: '',
    domainName: '',
  };
};

const getters = {
  getState: state => state,
  getSite: state => {
    return {
      office: state.office,
      token: state.token,
      existingDomain: state.existingDomain,
      domainName: state.domainName,
    };
  },
  getId: state => state.id,
  getHostingSettings: state => state.hostingSettings,
  getDomainName: state => state.domainName,
};

const mutations = {
  setUri(state, uri) {
    state.uri = uri;
  },
  setId(state, id) {
    state.id = id;
  },
  setOffice(state, office) {
    state.office = office;
  },
  setHostingSettings(state, hostingSettings) {
    state.hostingSettings = hostingSettings;
  },
  setTagManagerId(state, tagManagerId) {
    state.tagManagerId = tagManagerId;
  },
  setOauthClientId(state, oauthClientId) {
    state.oauthClientId = oauthClientId;
  },
  setToken(state, token) {
    state.token = token;
  },
  setDraftSiteVersion(state, draftSiteVersion) {
    state.draftSiteVersion = draftSiteVersion;
  },
  setProductionSiteVersion(state, productionSiteVersion) {
    state.productionSiteVersion = productionSiteVersion;
  },
  setExistingDomain(state, existingDomain) {
    state.existingDomain = existingDomain;
  },
  setDomainName(state, domainName) {
    state.domainName = domainName;
  },
};

const actions = {
  updateOffice({ commit }, office) {
    commit('setOffice', office);
  },
  updateSite({ commit }, site) {
    commit('setUri', site['@id']);
    commit('setId', site.id);
    commit('setHostingSettings', site.hostingSettings);
    commit('setTagManagerId', site.tagManagerId);
    commit('setOauthClientId', site.oauthClientId);
    commit('setToken', site.token);
    commit('setDraftSiteVersion', site.draftSiteVersion);
    commit('setProductionSiteVersion', site.productionSiteVersion);
    commit('setOffice', site.office);
    commit('setDomainName', site.domainName);
  },
  updateToken({ commit }, token) {
    commit('setToken', token);
  },
  updateHostingSettings({ commit }, hostingSettings) {
    commit('setHostingSettings', hostingSettings);
  },
  updateDomainName({ commit }, domainName) {
    commit('setDomainName', domainName);
  },
  updateExistingDomain({ commit }, existingDomain) {
    commit('setExistingDomain', existingDomain);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
