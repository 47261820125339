const initialState = () => {
  return {
    status: 'DRAFT',
    fontConfig: {},
    colorConfig: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
    template: '',
    description: '',
    fees: '',
    socialNetworks: [],
    content: [],
    site: '',
    legalNotice: '',
  };
};

const getters = {
  getState: state => state,

  getSocialNetworkUrlByType: state => type => {
    const social = state.socialNetworks.filter(social => social.type === type)[0];
    return social ? social.url : '';
  },

  getSocialNetworks: state => state.socialNetworks,

  getDescription: state => state.description,

  getFees: state => state.fees,

  getColorConfig: state => state.colorConfig,

  getFontConfig: state => state.fontConfig,

  getSite: state => state.site,

  getTemplate: state => state.template,

  getContent: state => state.content,

  getStatus: state => state.status,

  getLegalNotice: state => state.legalNotice,
};

const mutations = {
  setSite(state, site) {
    state.site = site;
  },

  setTemplate(state, template) {
    state.template = template;
  },

  addSocialNetwork(state, socialNetwork) {
    state.socialNetworks.push(socialNetwork);
  },

  removeSocialNetwork(state, socialNetwork) {
    state.socialNetworks = state.socialNetworks.filter(
      social => social.type !== socialNetwork.type
    );
  },

  modifySocialNetwork(state, socialNetwork) {
    state.socialNetworks = [
      ...state.socialNetworks.filter(social => social.type !== socialNetwork.type),
      socialNetwork,
    ];
  },

  setDescription(state, description) {
    state.description = description;
  },

  setFees(state, fees) {
    state.fees = fees;
  },

  setColorConfig(state, colorConfig) {
    state.colorConfig = colorConfig;
  },

  setFontConfig(state, fontConfig) {
    state.fontConfig = fontConfig;
  },

  setContent(state, content) {
    state.content = content;
  },

  setSocialNetworks(state, socialNetworks) {
    state.socialNetworks = socialNetworks;
  },

  setContentElementById(state, element) {
    state.content = [...state.content.filter(el => el.id !== element.id), element];
  },

  setStatus(state, status) {
    state.status = status;
  },

  setLegalNotice(state, legalNotice) {
    state.legalNotice = legalNotice;
  },
};

const actions = {
  updateSite({ commit }, site) {
    commit('setSite', site);
  },

  updateTemplate({ commit }, template) {
    commit('setTemplate', template);
  },

  updateSocialNetworks({ commit, state }, socialNetwork) {
    const { type, url } = socialNetwork;
    const social = {
      type,
      url,
    };

    if (!url) {
      commit('removeSocialNetwork', social);
      return;
    }

    if (state.socialNetworks.some(s => s.type === type)) {
      commit('modifySocialNetwork', social);
      return;
    }

    commit('addSocialNetwork', social);
  },

  updateDescription({ commit }, description) {
    commit('setDescription', description);
  },

  updateFees({ commit }, fees) {
    commit('setFees', fees);
  },

  updateColorConfig({ commit }, colorConfig) {
    commit('setColorConfig', colorConfig);
  },

  updateFontConfig({ commit }, fontConfig) {
    commit('setFontConfig', fontConfig);
  },

  updateAllFields({ commit }, siteVersion) {
    commit('setFontConfig', siteVersion.fontConfig);
    commit('setColorConfig', siteVersion.colorConfig);
    commit('setFees', siteVersion.fees);
    commit('setDescription', siteVersion.description);
    commit('setSite', siteVersion.site);
    commit('setTemplate', siteVersion.template['@id']);
    commit('setContent', siteVersion.content);
    commit('setStatus', siteVersion.status);
    commit('setLegalNotice', siteVersion.legalNotice);

    commit(
      'setSocialNetworks',
      siteVersion.socialNetworks.map(social => {
        const { type, url } = social;

        return {
          type,
          url,
        };
      })
    );
  },

  updateContentElementById({ commit }, element) {
    commit('setContentElementById', element);
  },

  updateStatus({ commit }, status) {
    commit('setStatus', status);
  },

  updateLegalNotice({ commit }, legalNotice) {
    commit('setLegalNotice', legalNotice);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
