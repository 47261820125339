<template>
  <div class="appointments-list">
    <div class="appointments-list__table">
      <div class="appointments-list__table-row appointments-list__table-heading-row">
        <div
          class="appointments-list__table-cell appointments-list__table-col--name"
        >
          <span>Nom/Prénom</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--date"
        >
          <span>Date RDV</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--hour"
        >
          <span>Horaires</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--subject"
        >
          <span>Objet du RDV</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--type"
        >
          <span>Type</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--actions"
        >
          <span>Actions</span>
        </div>
      </div>

      <div
        v-if="isEmpty"
        class="appointments-list__table-row appointments-list__message-empty"
      >
        <span class="text text--hint-large text--hint-dark">
          AUCUN RENDEZ-VOUS À AFFICHER.
        </span>
      </div>

      <div
        v-for="appointment in appointments['hydra:member']"
        v-else
        :key="appointment['@id']"
        class="appointments-list__table-row"
      >
        <div
          class="appointments-list__table-cell appointments-list__table-col--name"
        >
          <span
            >{{ appointment['participantLastName'].toUpperCase() }}
            {{ appointment['participantFirstName'] }}</span
          >
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--date"
        >
          <span>{{ formattedDateFR(appointment['startDatetime']) }}</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--hour"
        >
          <span
            >{{ formattedHour(appointment['startDatetime']) }} –
            {{ formattedHour(appointment['endDatetime']) }}</span
          >
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--subject"
        >
          <span>{{ appointment['subject'] }}</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--type"
        >
          <span>{{ translateType(appointment['appointmentType']) }}</span>
        </div>
        <div
          class="appointments-list__table-cell appointments-list__table-col--actions"
        >
          <div
            class="button button--x-small"
            @click="selectedAppointment = appointment"
          >
            <span>Voir</span>
          </div>
        </div>
      </div>
    </div>

    <AppointmentModal
      v-if="selectedAppointment !== null"
      :appointment="selectedAppointment"
      :accept-action="acceptAction"
      :cancel-action="cancelAction"
      :decline-action="declineAction"
      :defer-action="deferAction"
      :show-conference-url="showConferenceUrl"
      @refresh-appointments-list="refreshAppointmentsList"
    >
      <template #footer>
        <button class="button button--x-small" @click="selectedAppointment = null">
          <span>Fermer</span>
        </button>
      </template>
    </AppointmentModal>
  </div>
</template>

<script>
import { datesMixin } from '@/mixins/datesMixin';
import AppointmentModal from '@/components/dashboard/appointments/my-appointments/AppointmentModal';

export default {
  components: { AppointmentModal },

  mixins: [datesMixin],
  props: {
    appointments: {
      type: Object,
      value: {},
      required: true,
    },

    acceptAction: {
      type: Boolean,
      value: false,
    },

    deferAction: {
      type: Boolean,
      value: false,
    },

    declineAction: {
      type: Boolean,
      value: false,
    },

    cancelAction: {
      type: Boolean,
      value: false,
    },

    showConferenceUrl: {
      type: Boolean,
      value: false,
    },
  },

  data() {
    return {
      selectedAppointment: null,
    };
  },

  computed: {
    isEmpty() {
      return (
        this.appointments === undefined ||
        this.appointments.length === 0 ||
        this.appointments['hydra:member'] === undefined ||
        this.appointments['hydra:member'].length === 0
      );
    },
  },

  methods: {
    translateType(type) {
      if (type === 'PHONE_CALL') {
        return 'Téléphone';
      }

      if (type === 'VIDEO_CALL') {
        return 'Vidéo';
      }

      if (type === 'IN_PERSON') {
        return 'Cabinet';
      }
    },

    refreshAppointmentsList() {
      this.$emit('refresh-appointments-list');
      this.selectedAppointment = null;
    },
  },
};
</script>
