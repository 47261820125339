<template>
  <div class="legals">
    <h1>
      Mentions légales
    </h1>

    <vue-editor
      :editorToolbar="editorToolbar"
      :value="getSiteVersionLegalNotice"
      @input="updateSiteVersionLegalNotice($event)"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import Quill from 'quill';
import { mapGetters, mapActions } from 'vuex';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

export default {
  components: { VueEditor },

  data() {
    return {
      editorToolbar: [
        [{ header: [false, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
      ],
    };
  },

  computed: {
    ...mapGetters({
      getSiteVersionLegalNotice: 'siteVersion/getLegalNotice',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionLegalNotice: 'siteVersion/updateLegalNotice',
    }),
  },
};
</script>
