const initialState = function() {
  return {
    uri: '',
    name: '',
    email: '',
    specialities: [],
    logo: '',
    address: {
      addressLine1: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      longitude: '',
      latitude: '',
    },
    coworkers: [],
  };
};

const getters = {
  getState: state => state,
  getName: state => state.name,
  getEmail: state => state.email,
  getAddress: state => state.address,
  getSpecialities: state => state.specialities,
  getCoworkers: state => state.coworkers,
  getLogo: state => state.logo,
};

const mutations = {
  setName(state, value) {
    state.name = value;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setAddress(state, addressField) {
    state.address = {
      ...state.address,
      ...addressField,
    };
  },
  setSpecialities(state, specialities) {
    state.specialities = specialities;
  },
  setLogo(state, logo) {
    state.logo = logo;
  },
  setUri(state, uri) {
    state.uri = uri;
  },
  setCoworkers(state, coworkers) {
    state.coworkers = coworkers;
  },

  addCoworker(state) {
    state.coworkers.push({
      firstname: '',
      lastname: '',
      socialNetworks: [],
      barLaw: '',
      email: '',
      phoneNumber: '',
      speciality: '',
      profilePicture: '',
      description: '',
    });
  },

  removeCoworker(state, index) {
    state.coworkers.splice(index, 1);
  },

  modifyCoworker(state, coworker) {
    const { index, ...fields } = coworker;

    Object.assign(state.coworkers[index], fields);
  },

  addCoworkerSocialNetwork(state, socialNetwork) {
    const { index, ...social } = socialNetwork;

    state.coworkers[index].socialNetworks.push(social);
  },

  removeCoworkerSocialNetwork(state, socialNetwork) {
    const { index } = socialNetwork;

    state.coworkers[index].socialNetworks = state.coworkers[
      index
    ].socialNetworks.filter(social => social.type !== socialNetwork.type);
  },

  modifyCoworkerSocialNetwork(state, socialNetwork) {
    const { index, ...social } = socialNetwork;

    state.coworkers[index].socialNetworks = [
      ...state.coworkers[index].socialNetworks.filter(s => s.type !== social.type),
      social,
    ];
  },
};

const actions = {
  updateName({ commit }, value) {
    commit('setName', value);
  },
  updateEmail({ commit }, email) {
    commit('setEmail', email);
  },
  updateAddress({ commit }, addressField) {
    commit('setAddress', addressField);
  },
  updateSpecialities({ commit }, specialities) {
    commit('setSpecialities', specialities);
  },
  updateCoWorkers({ commit }, coworkers) {
    commit(
      'setCoworkers',
      coworkers.map(coworker => {
        coworker.socialNetworks = coworker.socialNetworks.map(social => {
          const { type, url } = social;

          return {
            type,
            url,
          };
        });

        return coworker;
      })
    );
  },

  setCoworkers(state, coworkers) {
    state.coworkers = coworkers;
  },

  addCoworker({ commit }) {
    commit('addCoworker');
  },

  removeCoworker({ commit }, coworker) {
    commit('removeCoworker', coworker);
  },

  updateCoworker({ commit }, coworker) {
    commit('modifyCoworker', coworker);
  },

  updateCoworkerSocialNetworks({ commit, state }, social) {
    if (!social.url) {
      commit('removeCoworkerSocialNetwork', social);
    }

    if (
      state.coworkers[social.index].socialNetworks.some(s => s.type === social.type)
    ) {
      commit('modifyCoworkerSocialNetwork', social);
      return;
    }

    commit('addCoworkerSocialNetwork', social);
  },

  updateLogo({ commit }, logo) {
    commit('setLogo', logo);
  },
  updateOffice({ commit }, office) {
    commit('setLogo', office.logo);
    commit('setSpecialities', office.specialities);
    commit('setAddress', office.address);
    commit('setEmail', office.email);
    commit('setName', office.name);
    commit('setUri', office['@id']);
    commit('setCoworkers', office.coworkers);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
