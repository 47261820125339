<template>
  <div class="appointments-types">
    <div class="appointments-types__checkbox-wrapper">
      <p-check
        v-model="inPersonAppointments"
        class="appointments-types__checkbox p-icon p-smooth"
        :disabled="!isAppointmentsModuleEnabled"
        @change="postAppointmentTypes"
      >
        <i slot="extra" class="icon mdi mdi-check" />
        Au cabinet
      </p-check>
    </div>

    <div class="appointments-types__checkbox-wrapper">
      <p-check
        v-model="videoCallsAppointments"
        class="appointments-types__checkbox p-icon p-smooth"
        :disabled="!isAppointmentsModuleEnabled"
        @change="postAppointmentTypes"
      >
        <i slot="extra" class="icon mdi mdi-check" />
        En visioconférence
      </p-check>
    </div>

    <div class="appointments-types__checkbox-wrapper">
      <p-check
        v-model="phoneCallsAppointments"
        class="appointments-types__checkbox p-icon p-smooth"
        :disabled="!isAppointmentsModuleEnabled"
        @change="postAppointmentTypes"
      >
        <i slot="extra" class="icon mdi mdi-check" />
        Par téléphone
      </p-check>
    </div>
  </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/check';
import { api } from '@/lib/api';
import { mapGetters } from 'vuex';

export default {
  components: { 'p-check': PrettyCheck },
  props: {
    schedule: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      inPersonAppointments: false,
      videoCallsAppointments: false,
      phoneCallsAppointments: false,
    };
  },

  computed: {
    ...mapGetters({
      isAppointmentsModuleEnabled: 'coworkers/appointmentsModuleEnabled',
    }),
  },

  watch: {
    schedule(oldSchedule, newSchedule) {
      if (oldSchedule.id !== newSchedule.id) {
        this.refreshAppointmentTypes();
      }
    },
    videoCallsAppointments(oldVideoCallsAppointments, newVideoCallsAppointments) {
      if (oldVideoCallsAppointments !== newVideoCallsAppointments) {
        this.$emit(
          'videoCallsAppointmentsCheckboxToggled',
          this.videoCallsAppointments
        );
      }
    },
  },

  mounted() {
    this.refreshAppointmentTypes();
  },

  methods: {
    async postAppointmentTypes() {
      await api.put(this.schedule.id, {
        inPersonAppointments: this.inPersonAppointments,
        videoCallsAppointments: this.videoCallsAppointments,
        phoneCallsAppointments: this.phoneCallsAppointments,
      });
    },

    refreshAppointmentTypes() {
      this.inPersonAppointments = this.schedule.inPersonAppointments;
      this.videoCallsAppointments = this.schedule.videoCallsAppointments;
      this.phoneCallsAppointments = this.schedule.phoneCallsAppointments;
    },
  },
};
</script>
