<template>
  <div>
    <CircularLoader v-if="isLoading" />
    <div v-if="user.site" class="dashboard">
      <header class="dashboard__header">
        <figure class="dashboard__header__logo">
          <router-link :to="{ name: 'DashboardHome' }">
            <img src="@/assets/svg/logo-solency-white.svg" alt="Logo Solency" />
          </router-link>
        </figure>
        <div class="dashboard__header__tabs">
          <router-link
            class="dashboard__header__tabs__tab"
            :to="{ name: 'SiteSettings' }"
          >
            VOTRE SITE
          </router-link>
          <router-link
            v-if="getSiteVersionStatus === 'PROD'"
            class="dashboard__header__tabs__tab"
            :to="{ name: 'Stats' }"
          >
            PERFORMANCES
          </router-link>
          <a class="dashboard__header__tabs__tab" :href="eLearningUrl">
            E-LEARNING
          </a>
          <router-link
            v-if="getSiteVersionStatus === 'PROD'"
            :class="[
              'dashboard__header__tabs__tab',
              {
                badge: unreadMessages,
              },
            ]"
            :to="{ name: 'Mailbox' }"
          >
            MESSAGES
          </router-link>
          <router-link
            v-if="getSiteVersionStatus === 'PROD'"
            class="dashboard__header__tabs__tab"
            :to="{ name: 'Appointments' }"
          >
            RENDEZ-VOUS
          </router-link>
        </div>
        <div class="dashboard__header__user-info">
          <span class="dashboard__header__user-info__name"
            >{{ user.firstname }} {{ user.lastname }}</span
          >
          <img src="@/assets/svg/Account.svg" />
          <a class="dashboard__header__user-info__logout-link" @click="logout">
            QUITTER
          </a>
        </div>
      </header>
      <div class="dashboard__body">
        <router-view
          :stats="stats"
          :messages="messages"
          :unreadMessages="unreadMessages"
          @mark-read="unreadMessages = false"
        ></router-view>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import Footer from '@/components/Footer';
import CircularLoader from '@/components/common/CircularLoader';

export default {
  components: { Footer, CircularLoader },
  data() {
    return {
      stats: {},
      user: {},
      messages: [],
      unreadMessages: false,
      isLoading: false,
    };
  },

  async created() {
    this.isLoading = true;

    const idAuth = this.getId;
    const { data: user } = await api.get(`/users/${idAuth}`);

    this.user = user;
    this.updateUser(user);

    this.isLoading = false;

    if (!user.site) {
      return this.$router.push({ name: 'Welcome' });
    }

    if (!this.getSite.id) {
      const { data: sites } = await api.get(`${user.site}`);
      this.updateSite(sites);
    }

    const { id } = this.getSite;
    const { data: site } = await api.get(`/sites/${id}`);
    this.updateSite(site);
    const { data } = await api.get(
      site.draftSiteVersion || site.productionSiteVersion
    );

    this.updateSiteVersion(data);
    this.updateOffice(data.site.office);

    const { data: stats } = await api.get(`/sites/${id}/performance`);
    this.stats = stats;

    const { data: messages } = await api.get('/messages', {
      params: {
        site: this.getSiteId,
      },
    });
    this.messages = messages['hydra:member'];

    this.unreadMessages = this.hasUnreadMessages();
  },

  computed: {
    ...mapGetters({
      getSiteVersionContent: 'siteVersion/getContent',
      getSiteVersionStatus: 'siteVersion/getStatus',
      getSite: 'site/getState',
      getSiteId: 'site/getId',
      getId: 'auth/getId',
      eLearningUrl: 'user/getELearningUrl',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersion: 'siteVersion/updateAllFields',
      updateSite: 'site/updateSite',
      updateOffice: 'office/updateOffice',
      updateCoworkers: 'office/updateCoWorkers',
      authLogout: 'auth/logout',
      updateUser: 'user/updateUser',
    }),

    async logout() {
      await this.authLogout();

      this.$router.push({ name: 'Connection' });
    },

    hasUnreadMessages() {
      return this.messages.some(message => !message.read);
    },
  },
};
</script>
