<template>
  <div class="email-client-actions">
    <EmailClientActionsHeader />

    <div class="email-client-actions__body">
      <p v-if="requestError" class="email-client-actions__error-message">
        Votre lien n'est pas valide.
      </p>

      <div v-else-if="!actionPerformed" class="email-client-actions__loader">
        <CircularLoader />
      </div>

      <p v-else-if="showErrorMessageAPI" class="email-client-actions__error-message">
        Nous avons rencontré un problème. Votre lien a peut-être expiré.
      </p>

      <p v-else-if="success" class="email-client-actions__error-message">
        {{ successMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import EmailClientActionsHeader from '@/components/email-client-actions/EmailClientActionsHeader';
import CircularLoader from '@/components/common/CircularLoader';

export default {
  components: { CircularLoader, EmailClientActionsHeader },

  data() {
    return {
      requestError: false,
      success: false,
      parameters: this.$route.query,
      actionPerformed: false,
    };
  },

  async mounted() {
    if (
      [
        this.parameters?.id,
        this.parameters?.token,
        this.parameters?.action,
      ].includes(undefined)
    ) {
      this.requestError = true;
      return;
    }

    await this.performAction(this.parameters.action);
  },

  computed: {
    requestUrl() {
      return (
        'appointment_deferral_requests/' +
        this.parameters.id +
        '?token=' +
        this.parameters.token
      );
    },

    showErrorMessageAPI() {
      return !this.requestError && this.actionPerformed && !this.success;
    },

    successMessage() {
      switch (this.parameters.action) {
        case 'accept':
          return 'Votre acceptation du nouveau créneau a bien été pris en compte.';
        case 'refuse':
          return 'Nous avons bien pris en compte le refus du nouveau créneau.';
        default:
          return '';
      }
    },
  },

  methods: {
    async performAction(action) {
      try {
        await api.put(this.requestUrl, {
          accepted: action === 'accept',
        });
      } catch (error) {
        return;
      } finally {
        this.actionPerformed = true;
      }

      this.success = true;
    },
  },
};
</script>
