<template>
  <Modal class="image-modal" width="medium">
    <template #body>
      <div v-if="imageUrl" class="image-modal__image">
        <img src="@/assets/svg/Check.svg" class="check-icon" alt="" />
        <img alt="" :src="imageUrl" class="image-input__image" ref="logoImg" />
      </div>
      <div v-else class="image-modal__input" @click="openFileExplorer">
        <input
          ref="fileInput"
          type="file"
          @change="uploadImage($event.target.files[0])"
        />
        <div class="image-modal__input__content">
          <img src="@/assets/svg/upload.svg" alt="" />
          <p class="link">Importez une image</p>
        </div>
      </div>
      <a class="link" @click="removeImage">Supprimer l'image</a>
      <div class="image-modal__photos">
        <p>
          Ou choisissez une image parmi une sélection d'images libres de droit :
        </p>
        <img
          class="image-modal__photo"
          v-for="photo in photos"
          :key="photo.id"
          :src="photo.urls.thumb"
          @click="selectPhoto(photo)"
        />
      </div>
    </template>
    <template #footer>
      <div v-if="!$v.image.fileSize">
        <p class="text text--2 text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Veuillez choisir une image de moins de 2mb
        </p>
      </div>
      <div class="image-modal__cta-container">
        <button
          class="button button--negative button--x-small"
          @click="$emit('cancel')"
        >
          Annuler
        </button>
        <button
          class="button button--x-small"
          @click="$emit('confirm', selectedImageUrl)"
        >
          Valider
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { Modal } from '@/components';
import { api } from '@/lib/api';

export default {
  components: { Modal },

  data() {
    return {
      photos: [],
      imageUrl: null,
      selectedImageUrl: null,
      image: {
        size: 0,
      },
    };
  },

  async created() {
    const { data } = await api.get('/photos');

    this.photos = data['hydra:member'].slice(0, 15);
  },

  methods: {
    openFileExplorer() {
      this.$refs.fileInput.click();
    },

    async uploadImage(file) {
      this.image = file;

      if (!this.$v.image.fileSize) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file, this.imageName);

      const { data } = await api.post('/images', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.imageUrl = data.contentUrl;
      this.selectedImageUrl = data.contentUrl;
    },

    removeImage() {
      this.imageUrl = null;
      this.selectedImageUrl = null;
    },

    selectPhoto(photo) {
      this.imageUrl = photo.urls.thumb;
      this.selectedImageUrl = photo.urls.full;
    },
  },

  validations: {
    image: {
      fileSize: image => {
        return image.size < 2000000;
      },
    },
  },
};
</script>
