import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import {
  auth,
  messages,
  office,
  site,
  siteVersion,
  user,
  appointments,
  coworkers,
  schedule,
} from '@/store/modules';

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: sessionStorage,
      paths: ['messages', 'office', 'site', 'siteVersion', 'coworker'],
    }),
    createPersistedState({
      storage: localStorage,
      paths: ['auth'],
    }),
  ],
  modules: {
    office,
    site,
    siteVersion,
    auth,
    messages,
    user,
    appointments,
    coworkers,
    schedule,
  },
});

export default store;
