<template>
  <div class="appointments-availabilities__day">
    <div @click="toggleIsEnabled">
      <p-check v-model="isEnabled" class="p-icon p-smooth">
        <i slot="extra" class="icon mdi mdi-check"></i>
      </p-check>
    </div>

    <p class="cursor--pointer" @click="toggleIsEnabled">
      {{ day }}
    </p>

    <TimeSelector
      v-for="(slot, index) in slots"
      :key="index"
      v-model="slot.currentValue"
      class="appointments-availabilities__time-selector"
      :min="slot.min"
      :max="slot.max"
      :disabled="!isEnabled || disabled"
      @change="availabilityChanged"
    />
  </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/check';
import { datesMixin } from '@/mixins/datesMixin';
import TimeSelector from '@/components/dashboard/appointments/appointment-settings/TimeSelector';

export default {
  components: { TimeSelector, 'p-check': PrettyCheck },
  mixins: [datesMixin],

  props: {
    day: {
      type: String,
      default: '',
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    coworker: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEnabled: null,
      slots: [
        {
          min: 7,
          max: 11,
          currentValue: null,
        },
        {
          min: 11,
          max: 15,
          currentValue: null,
        },
        {
          min: 15,
          max: 19,
          currentValue: null,
        },
        {
          min: 19,
          max: 22,
          currentValue: null,
        },
      ],
      mustAvailabilitiesBeRefreshed: true,
    };
  },

  computed: {
    formattedTimeSlots() {
      return this.slots.map(slot =>
        slot.currentValue.map(intValue => this.intToHour(intValue))
      );
    },
  },

  watch: {
    coworker(oldCoworker, newCoworker) {
      if (oldCoworker.id !== newCoworker) {
        this.mustAvailabilitiesBeRefreshed = true;
      }
    },
    availabilities(oldAvailabilities, newAvailabilities) {
      if (oldAvailabilities !== newAvailabilities && this.refreshAvailabilities) {
        this.refreshAvailabilities();
      }
    },
  },

  created() {
    this.refreshAvailabilities();
  },

  methods: {
    getJsonAvailabilities() {
      return [this.isEnabled, ...this.formattedTimeSlots];
    },

    refreshAvailabilities() {
      this.isEnabled = this.availabilities[0];

      for (let i = 1; i < this.availabilities.length; i++) {
        this.slots[i - 1].currentValue = [
          this.hourToInt(this.availabilities[i][0]),
          this.hourToInt(this.availabilities[i][1]),
        ];
      }

      this.mustAvailabilitiesBeRefreshed = false;
    },

    toggleIsEnabled() {
      this.isEnabled = !this.isEnabled;
      this.availabilityChanged();
    },

    availabilityChanged() {
      this.$emit('availabilityChanged');
    },
  },
};
</script>
