<template>
  <div class="settings-block">
    <p class="text text--instruction">
      QUELS RÉSEAUX SOCIAUX UTILISE VOTRE CABINET ?
    </p>

    <div class="settings-block__settings">
      <SocialNetworksInput
        :values="getSiteVersionSocialNetworks"
        @input="updateSiteVersionSocialNetworks($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SocialNetworksInput } from '@/components';

export default {
  components: { SocialNetworksInput },

  computed: {
    ...mapGetters({
      getSiteVersionSocialNetworks: 'siteVersion/getSocialNetworks',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionSocialNetworks: 'siteVersion/updateSocialNetworks',
    }),
  },
};
</script>
