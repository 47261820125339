<template>
  <div class="settings-block">
    <p class="text text--instruction">
      QUELLES SONT LES COORDONNÉES PRINCIPALES DE VOTRE CABINET ?
    </p>

    <div class="settings-block__settings">
      <div class="form-row">
        <input
          type="text"
          class="input input--medium"
          placeholder="Numéro de téléphone"
          :value="getOfficeAddress.phoneNumber"
          @input="updateOfficeAddress({ phoneNumber: $event.target.value })"
        />
        <input
          type="text"
          class="input input--medium"
          placeholder="Contact email principal"
          :value="getOfficeEmail"
          @input="updateOfficeEmail($event.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getOfficeAddress: 'office/getAddress',
      getOfficeEmail: 'office/getEmail',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeAddress: 'office/updateAddress',
      updateOfficeEmail: 'office/updateEmail',
    }),
  },
};
</script>
