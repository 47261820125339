<template>
  <div
    :class="['website-link', { 'website-link--with-margin-bottom': marginBottom }]"
  >
    <div class="website-link__link">
      <span>{{ url }}</span>
    </div>
    <a class="website-link__open-button" target="_blank" :href="url">
      <span>OUVRIR</span>
    </a>
  </div>
</template>

<script>
export default {
  props: { url: String, marginBottom: { type: Boolean, value: false } },
};
</script>
