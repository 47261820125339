<template>
  <div class="socials">
    <div class="form-row" v-for="(placeholder, type) in socials" :key="type">
      <div class="socials__icon">
        <img :src="socialNetworkIcon(type)" alt="" />
      </div>
      <input
        type="text"
        class="socials__input input"
        :placeholder="placeholder"
        :value="getSocialNetworkUrlByType(type)"
        @input="$emit('input', { type, url: $event.target.value })"
      />
      <img
        src="@/assets/svg/Check.svg"
        alt=""
        :class="{ hidden: !getSocialNetworkUrlByType(type) }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: Array,
  },

  data() {
    return {
      socials: {
        Facebook: 'facebook.com/',
        Twitter: '@',
        Linkedin: 'linkedin.com/',
        Youtube: 'youtube.com/',
      },
    };
  },

  methods: {
    getSocialNetworkUrlByType(type) {
      const social = this.values.filter(s => s.type === type)[0];
      return social ? social.url : '';
    },

    socialNetworkIcon(socialNetwork) {
      return require(`@/assets/svg/${socialNetwork}.svg`);
    },
  },
};
</script>
