<template>
  <div class="group-radio">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, Boolean],
  },

  mounted() {
    this.$on('radioInput', this.onInput);
  },

  methods: {
    onInput(e) {
      this.$emit('input', e.value);
    },
  },
};
</script>
