<template functional>
  <div class="radio-input-color">
    <slot />
    <div
      class="radio-input-color__color radio-input-color__color--primary"
      :style="{ backgroundColor: props.primary }"
    ></div>
    <div
      class="radio-input-color__color radio-input-color__color--secondary"
      :style="{ backgroundColor: props.secondary }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    primary: String,
    secondary: String,
  },
};
</script>
