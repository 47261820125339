function parseToken(token) {
  if (!token) return;
  return JSON.parse(atob(token.split('.')[1]));
}

const initialState = function() {
  return {
    token: '',
    id: '',
    expirationDate: '',
    roles: [],
  };
};

const getters = {
  getToken: state => state.token,
  getId: state => state.id,
  getExpirationDate: state => state.expirationDate,
  isActive: state => state.roles.includes('ROLE_USER'),
  isTokenValid: state => {
    return state.token && new Date().toISOString() < state.expirationDate;
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setId(state, id) {
    state.id = id;
  },
  setExpirationDate(state, expirationDate) {
    state.expirationDate = expirationDate;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
};

const actions = {
  updateAuth({ commit, dispatch }, token) {
    const authPayload = parseToken(token);
    commit('setToken', token);
    commit('setId', authPayload.id);
    commit('setRoles', authPayload.roles);
    commit('setExpirationDate', new Date(authPayload.exp * 1000).toISOString());
    dispatch('user/login', token, { root: true });
  },

  activate({ commit }) {
    commit('setRoles', ['ROLE_USER']);
  },

  logout({ commit, dispatch }) {
    commit('setToken', '');
    commit('setId', '');
    commit('setExpirationDate', '');

    dispatch('user/logout', null, { root: true });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
