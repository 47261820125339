<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        :class="[
          'modal-container',
          `modal-container--${width}`,
          { 'modal-container--padding-top': paddingTop },
        ]"
      >
        <div v-if="closeButton" class="modal-close-button__wrapper">
          <button class="modal-close-button" @click="requestClose">
            <img src="@/assets/svg/Bad-Check.svg" />
          </button>
        </div>

        <div v-if="icon" class="modal-icon">
          <img src="@/assets/svg/Check.svg" />
        </div>

        <div
          :class="[
            'modal-header',
            {
              'modal-header--padding-top': paddingTop,
            },
          ]"
        >
          <slot name="header"></slot>
        </div>

        <div class="modal-body">
          <slot name="body"> </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'small',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    paddingTop: {
      type: Boolean,
      default: true,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    requestClose() {
      this.$emit('closeModalRequest');
    },
  },
};
</script>
