<template>
  <div class="step step--wide">
    <h1 class="title--1">
      Parfait. <br />
      Votre cabinet a-t-il des réseaux sociaux actifs ?
    </h1>
    <p class="text text--instruction">QUELS RÉSEAUX SOCIAUX UTILISEZ-VOUS ?</p>

    <SocialNetworksInput
      :values="getSiteVersionSocialNetworks"
      @input="updateSiteVersionSocialNetworks($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SocialNetworksInput } from '@/components';

export default {
  components: { SocialNetworksInput },

  computed: {
    ...mapGetters({
      getSiteVersionSocialNetworks: 'siteVersion/getSocialNetworks',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionSocialNetworks: 'siteVersion/updateSocialNetworks',
    }),
  },
};
</script>
