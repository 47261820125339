<template functional>
  <div class="radio-input-typo">
    <slot />
    <span
      class="radio-input-typo__typo radio-input-typo__typo--primary"
      :style="{ 'font-family': props.primary }"
      >Lorem ipsum dolor sit amet</span
    >
    <span
      class="radio-input-typo__typo radio-input-typo__typo--secondary"
      :style="{ 'font-family': props.secondary }"
      >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</span
    >
  </div>
</template>

<script>
export default {
  props: {
    primary: String,
    secondary: String,
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Lora&family=Open+Sans&family=Manrope&family=Merriweather&family=Red+Rose&family=Roboto&family=Titillium+Web&display=swap');
</style>
