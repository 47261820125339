<template>
  <div class="radio-input">
    <input
      type="radio"
      class="radio-input__input"
      :id="id"
      :name="name"
      :value="value"
      :checked="checked"
      @change="onInput"
    />

    <label :class="['radio-input__label', modifier]" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, Boolean],
    checked: Boolean,
    name: String,
    modifier: String,
  },

  data() {
    return {
      id: `myid-${this._uid}`,
    };
  },

  methods: {
    onInput($value) {
      const { value } = this;
      const checked = Boolean($value);
      this.$parent.$emit('radioInput', { value, checked });
    },
  },
};
</script>
