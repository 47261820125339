import axios from 'axios';
import store from '../store';
import router from '../router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.response.use(null, error => handleCommonApiErrors(error));

export function apiSetJwt(jwt) {
  instance.defaults.headers.common = { Authorization: `Bearer ${jwt}` };
}

export function handleCommonApiErrors(error) {
  const { dispatch } = store;

  if (error.response.status === 401 && router.currentRoute.name !== 'Connection') {
    dispatch('auth/logout');

    router.push({
      name: 'Connection',
      query: { redirectTo: router.currentRoute.fullPath },
    });

    return Promise.reject(error);
  }

  return Promise.reject(error);
}

export const api = instance;
