<template>
  <div class="step">
    <h1>
      Pour commencer, quel est le nom <br />
      de votre cabinet ?
    </h1>
    <div class="form-row">
      <input
        v-model="officeName"
        class="input input--font-large"
        type="text"
        placeholder="Nom du cabinet"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      officeName: '',
    };
  },

  created() {
    this.officeName = this.getOfficeName;
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    officeName(value) {
      if (!this.$v.officeName.$invalid) {
        this.updateOfficeName(value);
      }
    },
  },

  computed: {
    ...mapGetters({
      getOfficeName: 'office/getName',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeName: 'office/updateName',
    }),
  },

  validations: {
    officeName: {
      required,
    },
  },
};
</script>
