<template>
  <div class="settings-block">
    <p class="text text--instruction">CHOISISSEZ UNE GAMME DE COULEURS</p>

    <div class="settings-block__settings">
      <ColorInput
        :selected-color="getSiteVersionColorConfig"
        @input="updateSiteVersionColorConfig"
      />
    </div>
  </div>
</template>

<script>
import { ColorInput } from '@/components/common/form';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { ColorInput },

  computed: {
    ...mapGetters({
      getSiteVersionColorConfig: 'siteVersion/getColorConfig',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionColorConfig: 'siteVersion/updateColorConfig',
    }),
  },
};
</script>
