<template>
  <div class="settings-block">
    <p class="text text--instruction">
      NOM ET ADRESSE DE VOTRE CABINET
    </p>

    <div class="settings-block__settings">
      <div class="form-row">
        <input
          class="input input--font-large"
          type="text"
          placeholder="Nom du cabinet"
          :value="getOfficeName"
          @input="updateOfficeName($event.target.value)"
        />
      </div>
      <div class="form-row">
        <input
          type="text"
          class="input"
          placeholder="N° et rue"
          :value="getOfficeAddress.addressLine1"
          @input="updateOfficeAddress({ addressLine1: $event.target.value })"
        />
      </div>
      <div class="form-row">
        <input
          type="text"
          class="input input--small"
          placeholder="Code postal"
          :value="getOfficeAddress.postalCode"
          @input="updateOfficeAddress({ postalCode: $event.target.value })"
        />
        <input
          type="text"
          class="input input--large"
          placeholder="Ville"
          :value="getOfficeAddress.city"
          @input="updateOfficeAddress({ city: $event.target.value })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getOfficeName: 'office/getName',
      getOfficeAddress: 'office/getAddress',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeName: 'office/updateName',
      updateOfficeAddress: 'office/updateAddress',
    }),
  },
};
</script>
