<template>
  <div class="message">
    <div class="message__container">
      <div class="message__container__contacts">
        <p class="text text--hint-large">
          {{ new Date(message.createdAt).toLocaleString() }}
        </p>
        <p class="text text--highlight">
          {{ message.firstname }} {{ message.lastname }}
        </p>
        <p class="text">{{ message.subject }}</p>
      </div>
      <div class="message__container__content">
        <p class="text">
          {{ expanded ? message.content : message.content.substring(0, 240) }}
        </p>
        <a v-if="message.content.length > 240" class="link" @click="togglePreview">{{
          this.expanded ? 'Voir moins' : 'Lire la suite'
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },

  props: {
    message: Object,
  },

  methods: {
    togglePreview() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
