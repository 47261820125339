<template>
  <Auth>
    <h1 class="title title--2">Connectez-vous</h1>
    <form @submit.prevent="login" class="connection-form">
      <div class="form-row">
        <input
          v-model="email"
          type="email"
          class="input"
          placeholder="Votre email"
        />
      </div>
      <div class="form-row">
        <input
          v-model="password"
          type="password"
          class="input"
          placeholder="********"
        />
      </div>
      <div v-if="connectionAvaibility === false">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Vos identifiants sont incorrects
        </p>
      </div>
      <button class="button connection-form__cta" type="submit">
        SE CONNECTER
      </button>
    </form>
    <p class="text text--with-margin-top">
      Vous avez oublié votre mot de passe ?

      <router-link class="link link--nested" :to="{ name: 'ForgotPassword' }">
        Créez-en un nouveau !
      </router-link>
    </p>
  </Auth>
</template>

<script>
import { mapActions } from 'vuex';
import { Auth } from '@/components';
import { api } from '@/lib/api';

export default {
  components: { Auth },

  data() {
    return {
      email: '',
      password: '',
      connectionAvaibility: null,
    };
  },

  watch: {
    email() {
      this.connectionAvaibility = null;
    },

    password() {
      this.connectionAvaibility = null;
    },
  },

  methods: {
    ...mapActions({
      updateAuth: 'auth/updateAuth',
    }),
    async login() {
      try {
        const {
          data: { token },
        } = await api.post('/auth_tokens', {
          email: this.email,
          password: this.password,
        });

        this.updateAuth(token);

        if (this.$route.query.redirectTo) {
          return this.$router.push(this.$route.query.redirectTo);
        }

        this.$router.push({ name: 'DashboardHome' });
      } catch {
        this.connectionAvaibility = false;
      }
    },
  },
};
</script>
