<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script type="text/javascript">
if (process.env.VUE_APP_BOTNATION_KEY) {
  window.onload = function() {
    window.chatboxSettings = {
      appKey: process.env.VUE_APP_BOTNATION_KEY,
      websiteId: process.env.VUE_APP_BOTNATION_ID,
      preventMessengerPreload: true,
      language: 'fr',
      autoStart: true,
    };
    (function(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://cbassets.botnation.ai/js/widget.js';
      js.async = true;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'chatbox-jssdk');
  };
}
</script>
