<template>
  <div class="settings-block">
    <p class="text text--instruction">
      LOGO DE VOTRE CABINET
    </p>

    <div class="settings-block__settings">
      <ImageInput
        upload-label="Importer votre logo"
        delete-label="Supprimer le logo"
        :image-url="getOfficeLogo"
        @upload="updateOfficeLogo($event)"
        @remove="updateOfficeLogo('')"
      />
    </div>
  </div>
</template>

<script>
import { ImageInput } from '@/components/common/form';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { ImageInput },

  computed: {
    ...mapGetters({
      getOfficeLogo: 'office/getLogo',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeLogo: 'office/updateLogo',
    }),
  },
};
</script>
