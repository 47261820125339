<template>
  <div class="design-settings">
    <h1 class="title--1 title--unspaced title--with-margin-top">
      Modifier le design de votre site
    </h1>

    <div class="design-settings__block">
      <TemplateSettings
        :templates="templates"
        :selected-template="getSiteVersionTemplate"
        @input="updateSiteVersionTemplate($event)"
      />
    </div>

    <div class="design-settings__block">
      <Color />
    </div>

    <div class="design-settings__block">
      <FontSettings
        :fonts="fonts"
        :selected-font="getSiteVersionFontConfig"
        @input="updateSiteVersionFontConfig($event)"
      />
    </div>
  </div>
</template>

<script>
import { TemplateSettings, Color, FontSettings } from '../SettingsBlocks';
import { api } from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { Color, TemplateSettings, FontSettings },

  data() {
    return {
      templates: [],
      colors: [
        {
          primary: '#1D1D1D',
          secondary: '#1CA7A8',
          reversed: false,
        },
        {
          primary: '#0A375C',
          secondary: '#91E1DC',
          reversed: false,
        },
        {
          primary: '#252F33',
          secondary: '#5C1446',
          reversed: false,
        },
        {
          primary: '#252F33',
          secondary: '#ED8431',
          reversed: false,
        },
        {
          primary: '#000000',
          secondary: '#FFFFFF',
          reversed: true,
        },
      ],
      fonts: [
        {
          primary: 'Manrope',
          secondary: 'Manrope',
        },
        {
          primary: 'Titillium Web',
          secondary: 'Titillium Web',
        },
        {
          primary: 'Merriweather',
          secondary: 'Lato',
        },
        {
          primary: 'Red Rose',
          secondary: 'Roboto',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getSiteVersionTemplate: 'siteVersion/getTemplate',
      getSiteVersionColorConfig: 'siteVersion/getColorConfig',
      getSiteVersionFontConfig: 'siteVersion/getFontConfig',
    }),
  },

  async created() {
    const { data } = await api.get('/templates');
    this.templates = data['hydra:member'];
  },

  methods: {
    ...mapActions({
      updateSiteVersionTemplate: 'siteVersion/updateTemplate',
      updateSiteVersionColorConfig: 'siteVersion/updateColorConfig',
      updateSiteVersionFontConfig: 'siteVersion/updateFontConfig',
    }),
  },
};
</script>
