<template>
  <div class="appointments-availabilities__wrapper">
    <div
      v-if="isScheduleDefined && isCoworkerDefined"
      class="appointments-availabilities"
    >
      <AvailabilityDay
        v-for="day in days"
        :key="day"
        :ref="'availabilityDay__' + day"
        :coworker="coworker"
        :availabilities="getJsonAvailabilities(days.indexOf(day))"
        :day="day"
        :disabled="!isAppointmentsModuleEnabled"
        @availabilityChanged="availabilitiesChanged = true"
      />
    </div>

    <button
      :class="[
        'appointments-availabilities__validate',
        'button',
        'button--x-small',
        { 'button--invalid': !availabilitiesChanged },
      ]"
      :disabled="!availabilitiesChanged"
      @click="postJsonAvailabilities"
    >
      Enregistrer mes disponibilités
      <i
        v-if="availabilitiesChanged"
        slot="extra"
        class="icon mdi mdi-18px mdi-content-save-edit"
      ></i>
      <i v-else slot="extra" class="icon mdi mdi-18px mdi-check"></i>
    </button>
  </div>
</template>

<script>
import AvailabilityDay from '@/components/dashboard/appointments/appointment-settings/AvalaibilityDay';
import { DEFAULT_DAILY_AVAILABILITIES } from '@/consts/availabilities';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { AvailabilityDay },

  data() {
    return {
      days: [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche',
      ],
      availabilitiesChanged: false,
      videoCallsAppointments: false,
      inPersonAppointments: false,
    };
  },

  computed: {
    ...mapGetters({
      coworker: 'coworkers/current',
      schedule: 'schedule/state',
      isAppointmentsModuleEnabled: 'coworkers/appointmentsModuleEnabled',
    }),

    jsonAvailabilities() {
      return this.days.map(day =>
        this.$refs['availabilityDay__' + day][0].getJsonAvailabilities()
      );
    },

    isScheduleDefined() {
      return this.schedule !== undefined;
    },

    isCoworkerDefined() {
      return this.coworker !== undefined;
    },
  },

  methods: {
    ...mapActions({
      updateScheduleJsonAvailabilities: 'schedule/updateJsonAvailabilities',
    }),

    async postJsonAvailabilities() {
      await this.updateScheduleJsonAvailabilities(this.jsonAvailabilities);

      this.availabilitiesChanged = false;
    },

    getJsonAvailabilities(weekDayNumber) {
      return (
        this.schedule?.jsonAvailabilities?.[weekDayNumber] ??
        DEFAULT_DAILY_AVAILABILITIES
      );
    },
  },
};
</script>
