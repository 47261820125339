<template>
  <footer
    :class="
      'dashboard__footer' + (marginTop ? ' dashboard__footer--margin-top' : '')
    "
  >
    <div>
      <img
        class="dashboard__footer__logo"
        src="@/assets/images/logo-solency-gray.png"
        alt="Logo Solency"
      />
    </div>
    <p class="dashboard__footer__text">
      Copyright {{ currentYear }}© Solency -
      <a href="https://www.solency.com/#Contact">Contact</a> -
      <a :href="apiURL + '/files/Conditions_d_utilisation-Solency.pdf'"
        >Conditions d’utilisation</a
      >
      -
      <a :href="apiURL + '/files/Notice_RGPD-Solency.pdf'" target="_blank"
        >Politique RGPD</a
      >
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',

  props: {
    marginTop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      apiURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
