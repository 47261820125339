import { api } from '@/lib/api';

const initialState = () => {
  return {
    all: [],
    current: null,
  };
};

const getters = {
  all: state => state.all,

  current: state => state.current,

  currentScheduleId: state => state.current?.schedule?.['@id'],

  appointmentsModuleEnabled: state => state.current?.appointmentsModuleEnabled,
};

const mutations = {
  setAll(state, coworkers) {
    state.all = coworkers;
  },

  setCurrent(state, coworker) {
    state.current = coworker;
  },
};

const actions = {
  async fetchAll({ commit, dispatch }) {
    const {
      data: { 'hydra:member': coworkers },
    } = await api.get('/coworkers');

    commit('setAll', coworkers);
    dispatch('updateCurrent', coworkers?.[0]);
  },

  async updateCurrentVideoConferenceCredentials(
    { commit, state },
    { videoConferenceLogin, videoConferencePassword }
  ) {
    const { data } = await api.put(state.current['@id'], {
      videoConferenceLogin,
      videoConferencePassword,
    });

    commit('setCurrent', data);
  },

  updateCurrent({ commit, dispatch }, coworker) {
    commit('setCurrent', coworker);

    if (coworker.schedule) {
      dispatch('schedule/fetch', coworker?.schedule?.['@id'], { root: true });
    }
  },

  async updateCurrentAppointmentsModuleEnabled(
    { commit, state },
    appointmentsModuleEnabled
  ) {
    const { data } = await api.put(state.current['@id'], {
      appointmentsModuleEnabled,
    });

    commit('setCurrent', data);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
