<template>
  <div class="global-settings__block">
    <p class="text text--instruction">
      URL DE VOTRE SITE
    </p>

    <WebsiteLink :url="formattedDomainName" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WebsiteLink from '@/components/WebsiteLink';

export default {
  components: { WebsiteLink },

  computed: {
    ...mapGetters({
      getDomainName: 'site/getDomainName',
    }),

    formattedDomainName() {
      return 'https://' + this.getDomainName;
    },
  },
};
</script>
